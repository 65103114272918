<template>
  <div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
      <div class="az-content-body pd-lg-l-40 d-flex flex-column">
        <h2 class="az-content-title mg-b-20">BWS</h2>
        <div class="az-content-label mg-b-3">Gets Started</div>
        <p class="mg-b-10">It's free to signup and only takes a minute.</p>
        <div class="wizard clearfix">
          <div class="steps clearfix">
            <ul>
              <li class="head d-inline-flex"><span :class="{active: activeTab === 0}" class="number">1</span> <span :class="{active: activeTab === 0}" class="title">Personal Information</span></li>
              <li class="head d-inline-flex"><span :class="{active: activeTab === 1}" class="number">2</span> <span :class="{active: activeTab === 1}" class="title">Organization Information</span>
              </li>
            </ul>
          </div>
          <div class="content clearfix">
            <form data-vv-scope="step1">
              <h3 class="title current" v-if="activeTab === 0">Personal Information</h3>
              <section class="body current" v-if="activeTab === 0">
                <p>Please keep your profile information always current in order to help you when you contact us.</p>
                <div class="row row-sm form-group">
                  <div class="col-md-5 col-lg-5">
                    <label class="form-control-label">First Name: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepOneSubmitted && errors.has('step1.first_name')}" class="form-control rounded" data-vv-as="First Name" data-vv-scope="step1" id="first_name" name="first_name" placeholder="Enter firstname" required type="text" v-model="credentials.user.first_name" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepOneSubmitted && errors.has('step1.first_name')">{{ errors.first('step1.first_name') }}</div>
                  </div>
                  <div class="col-md-5 col-lg-5 mg-t-20 mg-md-t-0">
                    <label class="form-control-label">Last Name: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepOneSubmitted && errors.has('step1.last_name')}" class="form-control rounded" data-vv-as="Last Name" data-vv-scope="step1" id="last_name" name="last_name" placeholder="Enter lastname" required type="text" v-model="credentials.user.last_name" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepOneSubmitted && errors.has('step1.last_name')">{{ errors.first('step1.last_name') }}</div>
                  </div>
                </div>
                <div class="row row-sm form-group ">
                  <div class="col-md-5 col-lg-5">
                    <label class="form-control-label">Email: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepOneSubmitted && errors.has('step1.email')}" class="form-control rounded" data-vv-as="Email" data-vv-scope="step1" id="email" name="email" placeholder="Enter Email" required type="text" v-model="credentials.user.email" v-validate="'required|email'">
                    <div class="invalid-feedback" v-if="stepOneSubmitted && errors.has('step1.email')">{{ errors.first('step1.email') }}</div>
                  </div>
                  <div class="col-md-5 col-lg-5 mg-t-20 mg-md-t-0">
                    <label class="form-control-label">Password: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepOneSubmitted && errors.has('step1.password')}" class="form-control rounded" data-vv-as="Password" data-vv-scope="step1" id="password" name="password" placeholder="Enter Password" required type="password" v-model="credentials.user.password" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepOneSubmitted && errors.has('step1.password')">{{ errors.first('step1.password') }}</div>
                  </div>
                </div>
              </section>
            </form>
            <form data-vv-scope="step2">
              <h3 class="title current" v-if="activeTab === 1">Organization Information</h3>
              <section class="body current" v-if="activeTab === 1">
                <p>Please keep your organization information always current in order to help you when you contact us.</p>
                <div class="row row-sm form-group">
                  <div class="col-md-10 col-lg-10">
                    <label class="form-control-label">Legal Name: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_name')}" class="form-control rounded" data-vv-as="Organization Name" data-vv-scope="step2" id="org_name" name="org_name" placeholder="Enter Organization Name" required type="text" v-model="credentials.user.org.name" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_name')">{{ errors.first('step2.org_name') }}</div>
                  </div>
                </div>
                <div class="row row-sm form-group">
                  <div class="col-md-5 col-lg-5">
                    <label class="form-control-label">Website: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_web')}" class="form-control rounded" data-vv-as="Organization Website" data-vv-scope="step2" id="org_web" name="org_web" placeholder="Enter Organization Website" required type="text" v-model="credentials.user.org.web" v-validate="'required'&& {url: {require_protocol: true }}">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_web')">{{ errors.first('step2.org_web') }}</div>
                  </div>
                  <div class="col-md-5 col-lg-5">
                    <label class="form-control-label">Phone: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_phone')}" class="form-control rounded" data-vv-as="Organization Phone" data-vv-scope="step2" id="org_phone" name="org_phone" placeholder="Enter Phone Number" required type="text" v-model="credentials.user.org.phone" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_phone')">{{ errors.first('step2.org_phone') }}</div>
                  </div>
                </div>
                <div class="row row-sm form-group">
                  <div class="col-md-5 col-lg-5 mg-t-20 mg-md-t-0">
                    <label class="form-control-label">Email: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.biz_email')}" class="form-control rounded" data-vv-as="Organization business email" data-vv-scope="step2" id="biz_email" name="biz_email" placeholder="Enter Business Email" required type="text" v-model="credentials.user.org.email" v-validate="'required|email'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.biz_email')">{{ errors.first('step2.biz_email') }}</div>
                  </div>

                </div>
                <div class="row row-sm form-group">
                  <div class="col-md-5 col-lg-5">
                    <label class="form-control-label">Address: <span class="tx-danger">*</span></label>
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_line1')}" class="form-control rounded" data-vv-as="Organization address line 1" data-vv-scope="step2" id="org_line1" name="org_line1" placeholder="Enter Address line 1" required type="text" v-model="credentials.user.org.address.line1" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_line1')">{{ errors.first('step2.org_line1') }}</div>
                  </div>
                  <div class="col-md-5 col-lg-5 mg-t-20 mg-md-t-0">
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_line2')}" class="form-control rounded" data-vv-as="Organization address line 1" data-vv-scope="step2" id="org_line2" name="org_line2" placeholder="Enter Address line 2" style="margin-top: 1.8rem;" type="text" v-model="credentials.user.org.address.line2" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_line2')">{{ errors.first('step2.org_line2') }}</div>
                  </div>
                </div>
                <div class="row row-sm form-group">
                  <div class="col-md-5 col-lg-5">
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_city')}" class="form-control rounded" data-vv-as="Organization address city" data-vv-scope="step2" id="org_city" name="org_city" placeholder="Enter City" required type="text" v-model="credentials.user.org.address.city" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_city')">{{ errors.first('step2.org_city') }}</div>
                  </div>
                  <div class="col-md-5 col-lg-5 mg-t-20 mg-md-t-0">
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_state')}" class="form-control rounded" data-vv-as="Organization address state" data-vv-scope="step2" id="org_state" name="org_state" placeholder="Enter State" required type="text" v-model="credentials.user.org.address.state" v-validate="'required'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_state')">{{ errors.first('step2.org_state') }}</div>
                  </div>
                </div>
                <div class="row row-sm form-group">
                  <div class="col-md-5 col-lg-5">
                    <input :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_zip')}" class="form-control rounded" data-vv-as="Organization address line 1" data-vv-scope="step2" id="org_zip" name="org_zip" placeholder="Enter Postal Code" required type="text" v-model="credentials.user.org.address.zip" v-validate="'required|numeric'">
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_zip')">{{ errors.first('step2.org_zip') }}</div>
                  </div>
                  <div class="col-md-5 col-lg-5 mg-t-20 mg-md-t-0">
                    <select :class="{'is-invalid': stepTwoSubmitted && errors.has('step2.org_country')}" aria-hidden="true" class="form-control select2 select2-hidden-accessible rounded" data-select2-id="1" data-vv-as="Organization address country" data-vv-scope="step2" name="org_country" tabindex="-1" v-model="credentials.user.org.address.country" v-validate="'required'">
                      <option label="Choose Country" value="null"></option>
                      <option v-bind:key="index" v-for="(member, index) in countries">{{member.name}}</option>
                    </select>
                    <div class="invalid-feedback" v-if="stepTwoSubmitted && errors.has('step2.org_country')">{{ errors.first('step2.org_country') }}</div>
                  </div>
                </div>
              </section>
            </form>
          </div>
          <div class="actions clearfix">
            <ul>
              <li>
                <button :disabled="activeTab === 0" @click="prev" class="btn btn-indigo rounded">Previous</button>
              </li>
              <li>
                <button @click="next" aria-hidden="false" class="btn btn-info rounded" v-if="activeTab === 0">Next</button>
                <button @click="submit" class="btn btn-success rounded" v-if="activeTab === 1">Submit</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="az-signup-footer" style="margin: 1rem;">
          <div style="float: right;">
            <p>Already have an account?
              <router-link :to="{name: 'login'}">Sign In</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import countries from "../../assets/data/countries";

export default {
  name: "Signup",
  components: { Multiselect },
  data() {
    return {
      loading: false,
      selected_type: null,
      credentials: {
        user: {
          first_name: null,
          last_name: null,
          email: null,
          password: null,
          org: {
            name: null,
            email: null,
            web: null,
            phone: null,
            address: {
              line1: null,
              line2: null,
              city: null,
              state: null,
              zip: null,
              country: null
            }
          }
        },
      },
      loggingIn: false,
      error: null,
      countries: countries,
      activeTab: 0,
      stepOneSubmitted: false,
      stepTwoSubmitted: false
    };
  },
  mounted() {

  },
  methods: {
    submit() {
      this.stepTwoSubmitted = true;
      this.$validator.validateScopes()
        .then(valid => {
          console.log(valid);
          if (valid) {
            this.signup();
          }
        });
    },
    async signup() {
      let self = this;
      try {
        self.loading = true;

        let reply = await this.$accounts.post('/accounts/users/register', self.credentials.user);
        this.signupSuccessful(reply);
      } catch (e) {
        this.signupFailed(e);
        console.log("error :", e);
      }
    },
    signupSuccessful(reply) {
      this.loading = false;
      this.$toastr.s("Successfully Registered.", "Success");
      this.$router.push('/auth/login');
    },
    signupFailed(err) {
      this.loading = false;
      let data = err.response.data;
      this.$toastr.e(data.description || data.detail, "Failed");
    },
    prev() {
      console.log("prev");
      this.activeTab = 0;
    },
    next() {
      console.log("next");
      this.stepOneSubmitted = true;
      this.$validator.validate()
        .then(valid => {
          if (valid) {
            this.activeTab = 1;
          }
        });

    }
  }
}
</script>

<style scoped>
.head {
  align-items: center;
}

.head .number {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #b4bdce;
  line-height: 2.1;
  display: block;
  color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 2.1;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 2.1;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (max-width: 1366px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

.head .number.active {
  background-color: #6675fa !important;
}

.head .title.active {
  color: #6675fa !important;
}
</style>
